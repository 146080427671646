<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> Banner管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <!-- <el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button> -->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="ad_name" label="广告名称"></el-table-column>
                
                <el-table-column prop="position_name" label="广告位置"></el-table-column>

                <el-table-column prop="b_image" label="图片">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.b_image" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                        <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
                    </template>
                </el-table-column>

                <el-table-column prop="pid" label="广告位置ID"></el-table-column>

                <el-table-column prop="is_show" label="是否显示"></el-table-column> 

                <el-table-column prop="description" label="描述"></el-table-column>

                <el-table-column prop="sort_order" label="排序"></el-table-column>

                <el-table-column prop="add_time" label="创建时间"></el-table-column>

                <el-table-column prop="update_time" label="更新时间"></el-table-column>

                 
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="50%">
            <el-form ref="form" :rules="rules" :model="form"  label-width="150px" >
                <el-form-item label="广告名称" prop="ad_name">
                    <el-input v-model="form.ad_name" placeholder="请输入标题名称"></el-input>
                </el-form-item>
                <el-form-item label="广告位置" prop="pid">
                            <el-select v-model="form.pid">
                                    <el-option
                                            v-for="item in ad_positions"
                                            :key="item.id"
                                            :label="item.position_name"
                                            :value="item.id">
                                    </el-option>
                            </el-select>
               </el-form-item>
                <el-form-item label="是否跳转其他小程序">
                            <el-switch v-model="form.is_other_app"></el-switch>
                </el-form-item>

                <el-form-item label="其他appid">
                    <el-input v-model="form.other_app_appid"></el-input>
                </el-form-item>

                <el-form-item label="链接">
                    <el-input v-model="form.ad_link"></el-input>
                </el-form-item>

                <el-form-item label="图片" prop="b_image">
                    <el-upload
                            class="avatar-uploader"
                            name="image"
                            with-credentials
                            :data="{id:this.form.b_imgid}"
                            :action="uploadUrl()"
                            :on-error="uploadError"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :on-progress="uploading"
                            :show-file-list="false"
                            :auto-upload="true"
                            enctype="multipart/form-data">
                        <img v-if="form.b_image" :src="form.b_image" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>

                </el-form-item>

                <el-form-item label="描述">
                    <el-input v-model="form.description"></el-input>
                </el-form-item>


                <el-form-item label="开始时间">
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.start_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>

                <el-form-item label="结束时间">
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.end_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>

                <el-form-item label="顺序">
                    <el-input v-model="form.sort_order"></el-input>
                </el-form-item>

                <el-form-item label="是否显示">
                            <el-switch v-model="form.is_show"></el-switch>
                </el-form-item>


            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'basetable',
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    ad_name: '',
                    img: '',
					b_image: '',
                    pid: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                ad_positions:[],
				rules: {
					ad_name: [
				        { required: true, message: '请输入标题', trigger: 'blur' }
				    ],
					b_image: [
						{ required: true, message: '请添加图片', trigger: 'blur' }
					],
					pid:[
						{ required: true, message: '请输入广告位置', trigger: 'blur' }
					],
				},
            }
        },
        created() {
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.b_title === this.del_list[i].b_title) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                console.log(res);
                this.form.b_imgid=res.data;
                this.form.b_image = URL.createObjectURL(file.raw);
                this.getData();
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number
                });
                // console.log(params);
                this.$api.post('Ad/getAdList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(res);
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.b_title;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.getAdPositions();
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id: null,
                        ad_link: null,
                        ad_name: null,
                        b_image: null,
                        b_imgid: null,
                        description: null,
                        end_time: null,
                        is_other_app: false,
                        other_app_appid: null,
                        pid: null,
                        sort_order: null,
                        start_time: null,
                        is_show:false
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                        id: item.id,
                        ad_link: item.ad_link,
                        ad_name: item.ad_name,
                        b_image: item.b_image,
                        b_imgid: item.b_imgid,
                        description: item.description,
                        end_time: item.end_time,
                        is_other_app: item.is_other_app==1?true:false,
                        other_app_appid: item.other_app_appid,
                        pid: item.pid,
                        sort_order: item.sort_order,
                        start_time: item.start_time,
                        is_show: item.is_show==1?true:false
                    };
                }
                this.editVisible = true;
                console.log(this.form);
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
				
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						
						this.form.is_other_app=this.form.is_other_app?1:0;
						this.form.is_show=this.form.is_show?1:0;

						var params=this.$qs.stringify(this.form);

						this.$api.post('Ad/saveAd', params, res => {
							this.getData();
							this.$message.success(res.msg);
							this.editVisible = false;
						}, err => {
							this.$message.error(err.msg);
						});

                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
            // 确定删除
            deleteRow(){
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                this.$api.post('Ad/deleteAd', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            getAdPositions(){
                this.$api.post('Ad/getAdPositions', null, res => {
                    this.ad_positions=res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
</style>
